<template>
  <div>
    <Navigation />
    <div class="pa-5 flex space-between">
      <ClientFilters />
      <ElButton @click="$refs.clientDialog.add()" type="primary"
        >Adauga Client</ElButton
      >
    </div>
    <!-- table -->
    <ResourceTable
      :query="query"
      :filter="filter"
      :default-sort="{ prop: 'data.name' }"
    >
      <ElTableColumn type="index" width="50" />
      <ElTableColumn prop="data.name" label="Nume" sortable />
      <ElTableColumn prop="data.email" label="Email" sortable />
      <ElTableColumn prop="data.phone" label="Telefon" sortable />
      <ElTableColumn prop="data.address" label="Adresa" sortable />
      <ElTableColumn prop="data.phone" label="Date Facturare">
        <template slot-scope="{ row }">{{
          row.data.invoiceDetails ? "da" : "nu"
        }}</template>
      </ElTableColumn>
      <!-- <ElTableColumn label="Creare" sortable sort-by="data.createdAt.seconds">
        <TimeAgo
          slot-scope="{ row }"
          v-if="row.data.createdAt"
          :date="row.data.createdAt.toDate()"
        />
      </ElTableColumn>
      <ElTableColumn label="Actualizare" sortable sort-by="data.updatedAt.seconds">
        <TimeAgo
          slot-scope="{ row }"
          v-if="row.data.updatedAt"
          :date="row.data.updatedAt.toDate()"
        />
      </ElTableColumn>-->
      <ElTableColumn align="right">
        <template slot-scope="{ row }">
          <ElButton @click="goToProjects(row.id)" size="mini">Lucrari</ElButton>
          <ElButton @click="$refs.clientDialog.edit(row.id)" size="mini"
            >Modifica</ElButton
          >
        </template>
      </ElTableColumn>
    </ResourceTable>
    <!-- dialogs -->
    <ClientDialog ref="clientDialog" />
  </div>
</template>

<script>
import FiltersMixin from "@/mixins/FiltersMixin";
import ClientDialog from "@/components/clients/ClientDialog";
import ClientFilters from "@/components/clients/ClientFilters";

export default {
  mixins: [FiltersMixin()],
  components: {
    ClientDialog,
    ClientFilters
  },
  computed: {
    query() {
      return this.$firestore().collection("clients");
    }
  },
  methods: {
    filter(row) {
      const { filters } = this;
      if (filters.name) {
        if (row.data.name.match(new RegExp(filters.name, "i")) === null) {
          return false;
        }
      }
      return true;
    },
    goToProjects(client) {
      this.$router.push({ path: "/projects", query: { client } });
    }
  }
};
</script>
