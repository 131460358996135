<template>
  <ResourceDialog
    ref="dialog"
    v-on="$listeners"
    :rules="rules"
    entity-name="Client"
    collection-name="clients"
  >
    <template slot-scope="{ data }">
      <!-- name field -->
      <ElFormItem label="Nume" prop="name">
        <ElInput v-model="data.name" />
      </ElFormItem>
      <!-- email field -->
      <ElFormItem label="Email" prop="email">
        <ElInput v-model="data.email" />
      </ElFormItem>
      <!-- phone field -->
      <ElFormItem label="Telefon" prop="phone">
        <ElInput v-model="data.phone" />
      </ElFormItem>
      <!-- address field -->
      <ElFormItem label="Adresa" prop="address">
        <ElInput v-model="data.address" type="textarea" :rows="5" />
      </ElFormItem>
      <!-- invoiceDetails field -->
      <ElFormItem label="Date Facturare" prop="invoiceDetails">
        <ElInput v-model="data.invoiceDetails" type="textarea" :rows="5" />
      </ElFormItem>
      <!-- invoiceDetails field -->
      <ElFormItem label="Date Facturare" prop="invoiceDetails">
        <ElInput v-model="data.invoiceDetails" type="textarea" :rows="5" />
      </ElFormItem>
    </template>
  </ResourceDialog>
</template>

<script>
import ResourceDialogMixin from "@/mixins/ResourceDialogMixin";

export default {
  mixins: [ResourceDialogMixin],
  computed: {
    rules() {
      return {
        name: [
          {
            required: true,
            message: "Numele este obligatoriu",
            trigger: "blur"
          }
        ]
      };
    }
  }
};
</script>
